import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate';

import glasschiebewand_00 from '../../images/glasschiebewand_00_1600.webp'
import glasschiebewand_01 from '../../images/glasschiebewand_01_1600.webp'
import glasschiebewand_02 from '../../images/glasschiebewand_02_1600.webp'
import glasschiebewand_03 from '../../images/glasschiebewand_03_1600.webp'
import glasschiebewand_thumb_00 from '../../images/glasschiebewand_00_400.webp'
import glasschiebewand_thumb_01 from '../../images/glasschiebewand_01_400.webp'
import glasschiebewand_thumb_02 from '../../images/glasschiebewand_02_400.webp'
import glasschiebewand_thumb_03 from '../../images/glasschiebewand_03_400.webp'

const context = {
  title: 'Glas&#173;schiebewand',
  description: 'Individuelle und transparente Raumtrennsysteme, die sich vollständig ohne Schwelle öffnen lassen',
  text: 'Glasschiebewände, oder auch Glastrennwände genannt, ermöglichen es, Räume flexibel, offen, und konzeptionell zu nutzen. Kein Platz wird verschenkt - mit einer Glasschiebewand lassen sich beispielsweise selten benötigte, große Räume in Büroräumlichkeiten verwandeln, ohne dass hierbei das große Raumgefühl verloren geht, der Raum wird optimal ausgelastet. Auch lassen sich Eingangsbereiche kurzerhand für den Kunden abtrennen ohne dass die Transparenz oder die Helligkeit des Bereichs sich verringert. \n\n' +

  'Nicht von der Stange -  jede Glasschiebewand ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten technisch und optisch perfekt ineinandergreifen, kann eine langfristig zufriedenstellende Eingangssituation entstehen. Wir von Automatiktür24 beraten Sie gerne und stehen für sämtliche Fragen zur Verfügung –smart, effizient, online.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    'design',
    //'din18650',
    //'energieeinsparung',
    'modernisierung',
    //'notStrom',
    //'personenfrequenz',
    'platzsparend',
    'transparenz',
    //'einbruchhemmung',
    //'zutrittskontrolle',
    //'fluchtweg',
  ],
  legalRequirements: [
    //'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: glasschiebewand_00,
      thumbnail: glasschiebewand_thumb_00,
      originalAlt: 'Glasschiebewand'
    },
    {
      original: glasschiebewand_01,
      thumbnail: glasschiebewand_thumb_01,
      originalAlt: 'Glasschiebewand'
    },
    {
      original: glasschiebewand_02,
      thumbnail: glasschiebewand_thumb_02,
      originalAlt: 'Glasschiebewand'
    },
    {
      original: glasschiebewand_03,
      thumbnail: glasschiebewand_thumb_03,
      originalAlt: 'Glasschiebewand'
    },
  ],
  configurator: false,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
